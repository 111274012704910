export const TYPE_MAT_MED_SIMPRO = 'MAT_MED_SIMPRO';
export const TYPE_MAT_MED_BRASINDICE = 'MAT_MED_BRASINDICE';

export const EXPENSE_CD_TYPES = ['01', '02', '03', '05', '07', '08'];

export const parseCDType = cd => {
    switch (cd) {
        case '01':
        case '05':
        case '07':
            return 'Taxas, diarias e gases';
        case '02':
            return 'Medicamentos';
        case '03':
        case '08':
            return 'Materiais';
        default:
            return cd;
    }
}

export const MAT_MED_OPTIONS = [
    { label: 'Simpro', value: TYPE_MAT_MED_SIMPRO },
    { label: 'Brasindice', value: TYPE_MAT_MED_BRASINDICE },
];