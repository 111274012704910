<template>
    <div class="selectors">
        <multiselect
            v-if="!table"
            track-by="value"
            label="label"
            placeholder="Selecionar tabela..."
            v-model="table"
            :options="tableOptions"
            :showLabels="false"
            :internalSearch="false"
            :searchable="false"
            class="with-border"
            @select="onSelectTable"
        >
            <template slot="caret">
                <div v-if="!loading" class="chevron">
                    <ChevronDown />
                </div>
                <div v-else class="loading-icon">
                    <b-spinner small type="grow" variant="primary"></b-spinner>
                </div>
            </template>
            <template slot="noOptions">
                Nenhuma opção
            </template>
            <template slot="noResult">
                Nenhum resultado
            </template>
        </multiselect>

        <div v-else class="custom-input-prefix">
            <div
                class="profile-prefix"
                @click="clearSelectors()"
            >
                <Ellipsis>{{ table.label }}</Ellipsis>
            </div>
            <multiselect
                track-by="id"
                placeholder="Selecionar item..."
                v-model="materialMedicine"
                :options="materialMedicines"
                :showLabels="false"
                :internalSearch="false"
                :searchable="true"
                @search-change="debounceSearch"
                @select="onSelectItem"
            >
                <template slot="caret">
                    <div v-if="!loading" class="chevron">
                        <ChevronDown />
                    </div>
                    <div v-else class="loading-icon">
                        <b-spinner small type="grow" variant="primary"></b-spinner>
                    </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <OptionLineSimpro v-if="option.type === 'Simpro'" :simproProps="option.simpro" />
                    <OptionLineBrasindice v-if="option.type === 'Brasindice'" :brasindiceProps="option.brasindice" />
                </template>
                <template slot="option" slot-scope="{ option }">
                    <OptionSimpro v-if="option.type === 'Simpro'" :simproProps="option.simpro" />
                    <OptionBrasindice v-if="option.type === 'Brasindice'" :brasindiceProps="option.brasindice" />
                </template>
                <template slot="noOptions">
                    Nenhuma opção
                </template>
                <template slot="noResult">
                    Nenhum resultado, verifique se o item pesquisa foi importado para o sistema.
                </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import api from '@matMed/api'
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager';
import * as MATMED from '@matMed/utils/constants'

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Ellipsis: () => import('@/components/General/Ellipsis'),
        OptionLineSimpro: () => import('@matMed/components/OptionLineSimpro'),
        OptionLineBrasindice: () => import('@matMed/components/OptionLineBrasindice'),
        OptionSimpro: () => import('@matMed/components/OptionSimpro'),
        OptionBrasindice: () => import('@matMed/components/OptionBrasindice'),
    },
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            loading: false,
            table: null,
            tableOptions: MATMED.MAT_MED_OPTIONS,
            materialMedicine: null,
            materialMedicines: [],
            debounceSearch: Function
        }
    },
    created() {
        this.debounceSearch = debounce(this.searchItems, 300);
    },
    mounted() {
    },
    methods: {
        clearSelectors() {
            this.materialMedicines = []
            this.table = null
            this.materialMedicine = null
            this.onSelectItem(null)
        },
        onSelectTable(table) {
            this.table = table
        },
        onSelectItem(materialMedicine) {
            this.$emit('select', materialMedicine)
            this.$emit('input', materialMedicine)
        },
        searchItems(query) {
            this.loading = true;
            api.searchItems(this.clinic.id, this.table.value, query)
                .then(({ data }) => (this.materialMedicines = data))
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false));
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(materialMedicine) {
                if (!this.materialMedicine && materialMedicine) {
                    this.materialMedicines.push(materialMedicine)
                    this.table = this.tableOptions.find(table => table.label === materialMedicine.type)
                    this.materialMedicine = materialMedicine
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.selectors {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.custom-input-prefix {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    height: 38px;
    border: 1px solid var(--neutral-300);
    padding: 15px 0 15px 16px;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
    .profile-prefix {
        max-width: 20%;
        font-weight: 400;
        color: var(--neutral-500);
        cursor: pointer;
    }
}
.loading-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
}
.materialMedicine-label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--dark-blue);
}
.code {
    font-size: 0.8rem;
    color: var(--blue-500);
}
</style>